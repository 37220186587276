import React from "react"
import styles from "../css/Footer.module.css"
import Link from "./Link"
import menuLinks from "../constants/navmenu"
import socialLiks from "../constants/socialLinks"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {menuLinks.map((item, index) => {
          return (
            <Link key={index} to={item.link}>
              {item.text}
            </Link>
          )
        })}
      </div>
      <div className={styles.socialLinks}>
        {socialLiks.map((item, index) => {
          return (
            <Link key={index} to={item.url}>
              {item.icon}
            </Link>
          )
        })}
      </div>
      <div>
        Copyright &copy; DataRight Ltd {new Date().getFullYear()}. All rights
        reserved
      </div>
    </footer>
  )
}

export default Footer
