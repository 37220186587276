import React from "react"
import "../css/Layout.css"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "./SEO/SEO"

const Layout = ({ children, title, description, image, contentType }) => {
  const { pathname } = useLocation()
  return (
    <>
      <SEO
        title={title}
        description={description}
        image={image}
        path={pathname}
        contentType={contentType}
      />
      <main>
        <Navbar />
        {children}
        <Footer />
      </main>
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
}

export default Layout
