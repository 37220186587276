import React, { Component } from "react"
import menuLinks from "../constants/navmenu"
import styles from "../css/Navbar.module.css"
import socialLiks from "../constants/socialLinks"
import { FaAngleDown } from "react-icons/fa"
import Link from "./Link"
import Logo from "./Logo"

export default class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDropLinks: false,
    }
  }

  showLinksHandler() {
    console.log("arrow clicked")
    this.setState(prevState => ({
      showDropLinks: !prevState.showDropLinks,
    }))
  }

  renderLinks() {
    return menuLinks.map((item, index) => {
      return (
        <li key={index} className={styles.navLink}>
          <Link to={item.link}>{item.text}</Link>
        </li>
      )
    })
  }

  render() {
    return (
      <div className={styles.navbar}>
        <div className={`${styles.navTopBar} ${styles.shadow}`}>
          <div className={styles.navCenter}>
            <Link to="/">
              <div className={styles.navHeader}>
                <Logo />
                {/* <h1>DataRight</h1> */}
              </div>
            </Link>
            <div className={styles.navLinksTop}>
              <ul className={styles.navLinks}>{this.renderLinks()}</ul>
            </div>
            <div className={styles.navSocialLinks}>
              {socialLiks.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.icon}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.drawer}>
          <div
            className={
              this.state.showDropLinks
                ? `${styles.drawerLinks} ${styles.shadow} ${styles.drawerLinksShown}`
                : `${styles.drawerLinks} ${styles.shadow} ${styles.drawerLinksHidden}`
            }
          >
            <ul className={styles.navLinks}>{this.renderLinks()}</ul>
          </div>
          <div className={styles.circle}>
            <button
              className={
                this.state.showDropLinks
                  ? `${styles.showLinksButton} ${styles.rotated}`
                  : styles.showLinksButton
              }
              onClick={() => this.showLinksHandler()}
            >
              <FaAngleDown></FaAngleDown>
            </button>
          </div>
        </div>
      </div>
    )
  }
}
