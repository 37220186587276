export default [
  // {
  //   text: "Product",
  //   link: "/product",
  // },
  {
    text: "Demo",
    link: "http://demo.dataright.ai",
  },
  {
    text: "Blog",
    link: "/blog",
  },
  {
    text: "Use cases",
    link: "/usecases",
  },
  {
    text: "Faq",
    link: "/faq",
  },
  {
    text: "Contact",
    link: "/contact",
  },
]
