import React from "react"
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare } from "react-icons/fa"

export default [
  {
    icon: <FaLinkedin />,
    url: "https://www.linkedin.com/company/dataright-ai",
  },
  {
    icon: <FaFacebookSquare />,
    url: "https://www.facebook.com/datarightai",
  },
  {
    icon: <FaTwitterSquare />,
    url: "https://twitter.com/dataright_ai",
  },
]
