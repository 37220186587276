import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"

const Link = ({ to, children, className }) => {
  const { pathname } = useLocation()
  const external = to.includes("http://") || to.includes("https://")
  if (external) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    )
  } else {
    return (
      <AniLink fade to={to} className={className} state={{ prevUrl: pathname }}>
        {children}
      </AniLink>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
}

export default Link
