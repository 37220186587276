import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDescription: description
        author
        siteUrl
        image
        twitterUsername
      }
    }
  }
`
const SEO = props => {
  const {
    title,
    description,
    image: featuredImage,
    contentType,
    // author,
    path,
  } = props
  const siteMetadata = useStaticQuery(getData).site.siteMetadata
  const {
    siteTitle,
    siteDesctiption,
    // author,
    siteUrl,
    image: siteImage,
    twitterUsername,
    // linkedInAuthor,
  } = siteMetadata
  const image = `${siteUrl}${featuredImage || siteImage}`

  // LinkedIn values
  const liType = contentType ? (
    <meta property="og:type" content={contentType}></meta>
  ) : null
  // const liAuthor = (
  //   <meta name="author" content={author ? author : linkedInAuthor} />
  // )

  return (
    <>
      <Helmet title={title} htmlAttributes={{ lang: "en" }}>
        <meta name="description" content={description || siteDesctiption} />
        <link
          rel="alternate"
          type="application/rss+xml"
          title={`RSS Feed for ${siteTitle}`}
          href={`${siteUrl}/rss.xml`}
        />
        <meta name="image" content={image} />
        {/* Twitter card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:author" content={twitterUsername} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/* LinkedIn and Facebook card */}
        <meta property="og:image" content={image} />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={description || siteDesctiption}
        />
        <meta property="og:url" content={`${siteUrl}${path}`} />
        {liType}
      </Helmet>
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default SEO
